import '../../css/scss/components/_dropdown_select.scss'
import '../../css/scss/components/_forms.scss'

import is from 'is_js'
import Vue from 'vue'

Vue.component('dropdown-select', {
  props: {
    type: { default: 'button' },
    options: { default: [] },
    value: {},
    open: { default: false},
    placeholder: {},
    optionsTop: { default: false}
  },

  data () {
    this.loadOptions(this.options)
    return {        
      inputText: '',
      filterPos: -1,
      dropdownContentHeight: 53
    }
  },

  computed: {
    valueInput(){
      if(!this.open){
        return this.selected ? (this.selected.labelOption || this.selected.label) : ''
      }
      return this.inputText
    },
    styleTop() {
      if (!this.optionsTop) {
        return
      }
      const top = this.dropdownContentHeight + 10
      return {
        'padding-top': 0,
        top: `-${top}px`
      }
    },
    selectText() {
      if (this.value) {
        return this.value.label || this.placeholder
      }
      return this.placeholder
    }
  },

  watch: {
    value(value){
      this.selected = value || {}
    },
    options(value) {
      this.loadOptions(value)
    }
  },

  mounted(){
    let self = this;

    window.addEventListener('click', function(e){
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)){
        self.open = false
      }
    })
  },

  methods: {
    loadOptions(options) {
      let optionsData = options
      let hasObjectData = true
      if (optionsData.length && is.not.object(optionsData[0])) {
        hasObjectData = false
        optionsData = optionsData.map(s => ({ label: s, value: s, visible: true }))
      }
      let selected = this.value || {}
      if(typeof selected === 'string'){
        selected = optionsData.find(o => o.value = selected)
      }
      this.selected = selected
      this.optionsData = optionsData
      this.hasObjectData = hasObjectData
      
      this.withInfo = (optionsData[0])?!!optionsData[0].info:false
      this.optionsFiltered = optionsData
    },
    selectOption(option) {
      if(option.clickable === false) return
      this.open = false
      this.$emit('input', option)
      this.$emit('change', option)
    },
    toggleDropdown() {
      this.open = !this.open
      setTimeout(() => this.dropdownContentHeight = this.$refs.dropdownContent.offsetHeight)
    },
    changeValue($event){
      this.inputText = $event.target.value
      let text = new RegExp(this.inputText.toLowerCase(), 'g')
      this.optionsFiltered = this.optionsData.filter(d => {
        return (d.labelOption || d.label).toLowerCase().match(text)
      })
      if(this.filterPos >= this.optionsFiltered.length - 1){
        this.filterPos = -1
      }
    },
    keyDown($event){
      if($event.keyCode === 13){  // ENTER
        let pos = this.filterPos < 0 ? 0 : this.filterPos
        let first = this.optionsFiltered[pos]
        if(first) this.selectOption(first)
        this.$refs.inputDom.blur()
      }
      if($event.keyCode === 27){  // ESC
        this.open = false
        this.$refs.inputDom.blur()
      }
      if($event.keyCode === 38){  // UP
        if(this.filterPos > 0) this.filterPos--
      }
      if($event.keyCode === 40){  // DOWN
        if(this.filterPos < this.optionsFiltered.length - 1) this.filterPos++
      }
    }
  },

  template:
    `<div class="dropdown-beyond" :class="{ 'dropdown-button': type==='button', 'dropdown-link': type==='link', 'dropdown-input': (type==='input' || type==='input-white'), 'dropdown-input-white': type==='input-white' }">
      <span v-if="type!=='input' && type!=='input-white'" class="selected" :class="{ active: selected.label, open }" @click="toggleDropdown" v-html="selectText"></span>
      <span <span v-if="type === 'link'"class="open-indicator">↓</span> 
      
      <div v-if="type==='input' || type==='input-white'" class="input-wrapper" :class="{ open }">
        <input ref="inputDom" class="form-control" :class="{ active: selected.label }" @click="toggleDropdown" :placeholder="placeholder" :value="valueInput" @input="changeValue($event)" @keydown="keyDown($event)">
        <span class="open-indicator">↓</span>
      </div>
      <div ref="dropdownContent" :style="styleTop" class="dropdown-content" :class="{ 'with-info': withInfo, open }">
        <template v-for="(option, $index) in optionsFiltered" v-show="option.visible">
          <label @click="selectOption(option)" :class="{ active: selected === option || filterPos === $index, 'has-info': option.info, block: option.clickable === false }">
            <div v-html="option.labelOption? option.labelOption: option.label"></div>
            <span v-if="option.info" class="info">{{ option.info }}</span>
          </label>
          </template>
      </div>
    </div>`
})