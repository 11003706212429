import '../../css/scss/components/_stars.scss'

import Vue from 'vue'
import utils from '../commons/utils'

Vue.component('stars', {
  props: {
    stars: {default: 5, type: Number},
    background: {default: 'white'},
    color: {default: '#43d0de'},
    margin: {default: '0.33rem'},
    size: {default: '100%'},
    scale: {default: 5, type: Number},
    value: {type: Number},
    type: { default: 'value' },
    rating: { default: 0 }
  },

  data(){
    return {
      rating: this.value || this.rating,
      percentages: this.calcPercentages(this.value),
      tmpValue: this.value,
      alreadyLeave: true
    }
  },

  watch: {
    value(value){
      this.percentages = this.calcPercentages(value)
    },
  },

  methods:{
    calcPercentages(value){
      let realValue = value * this.stars / this.scale
      if(realValue > this.scale) realValue = this.scale
      let chunk = this.scale / this.stars
      let percentages = []
      let length = 65
      for(let i=0; i<this.stars; i++) {
        if(realValue >= chunk * i) {
          if(realValue >= chunk * (i+1)) {
            percentages.push(length)
          } else {
            percentages.push(length * (realValue - chunk * i) / chunk)
          }
        } else {
          percentages.push(0)
        }
      }
      return percentages
    },
    onHoverStars(index) {
      if (this.type === 'selector') {
        if (this.alreadyLeave) {
          this.alreadyLeave = false
          this.tmpValue = this.rating
        }
        this.rating = index
        this.percentages = this.calcPercentages(index)
      }
    },
    onMouseLeave() {
      if (this.type === 'selector') {
        this.alreadyLeave = true
        this.rating = this.tmpValue
        this.percentages = this.calcPercentages(this.rating)
      }
    },
    onSelectStar(i) {
      if (this.type === 'selector') {
        this.value = i
        this.tmpValue = i
        this.$emit('input', i)
      }
    },
  },

  template:
    `<div stars @mouseleave="onMouseLeave">
      <div v-for="i in stars" :style="{marginRight: margin, fontSize: size }" :class="{ 'stars-selector': type === 'selector' }" @mouseover="onHoverStars(i)" @click="onSelectStar(i)" >
        <span class="progress" :style="{backgroundColor: color, width: percentages[i-1] + '%'}"></span>
        <span class="bc-icon-star2" :style="{color: background}"></span>
        <span class="bc-icon-star1" :style="{color: color}"></span>
      </div>
    </div>`
})