import '../../css/scss/components/_modal.scss'

import Vue from 'vue'
import utils from '../commons/utils'

let zIndex = 100
let visibles = []
let html = document.querySelector('html')
let blockPop = false

let closeModal = ()=>{
  let last = visibles.pop()
  last.status = false
  if(last.onHide) last.onHide()
  if(!visibles.length){
    html.style.overflow = ''
  }
}

window.addEventListener('popstate', (e) => {
  if(blockPop){
    return blockPop = false
  }
  if(visibles.length){
    closeModal()
    return false;
  }
})

document.onkeydown = function(evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) isEscape = (evt.key == "Escape" || evt.key == "Esc") 
    else isEscape = (evt.keyCode == 27)
    if (isEscape && visibles.length) closeModal()
};

Vue.component('modal', {
  mounted(){

  }, 
  data(){
    return{
      status: false,
      zIndex: zIndex
    }
  },
  methods:{
    show(onShow, onHide){
      this.status = true
      html.style.overflow = 'hidden'
      this.zIndex = ++zIndex
      visibles.push(this)
      blockPop = true
      location.href = '#'+utils.random()
      if(onShow) onShow()
      this.onHide = onHide
    },
    hide: ()=>{
      closeModal()
      window.history.go(-1)
    }
  },

  template: `
    <div modal v-if="status" :style="{zIndex: zIndex}">
      <slot></slot>
    </div>
  `
})