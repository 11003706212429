import Vue from 'vue'
import Vue2Storage from 'vue2-storage'

let observers = {}
let apartmentTimeout = 0
let context

Vue.use(Vue2Storage, {
  prefix: 'bc_',
  ttl: 60 * 60 * 24 * 7 * 1000 // a week
})

export default {
  init(options) {
    context = options.context
  },

  getApartment(that) {
    let key = 'details_'
    let data = context.$storage.get(key + that.id)
    if (data) return data
    observers[key + that.id] = that

    clearTimeout(apartmentTimeout)
    apartmentTimeout = setTimeout(function () {
      let ids = Object.keys(observers).filter(k => !k.indexOf(key)).map(k => observers[k].id)
 
        const result = [];
        let chunkSize=20;
        
        for (let i = 0; i < ids.length; i += chunkSize) {
          const chunk = ids.slice(i, i + chunkSize);
          result.push(chunk);
        }
        
        
      result.map(_chunked=>{
        let ids_=_chunked.join(',')
        context.$http.get(`/detail-search/${that.type}/${ids_}`).then((result) => {
          result.body.data.forEach(a => {
            let component = observers[key + a.id]
            delete observers[key + a.id]
            component.setData(a)
            context.$storage.set(key + a.id, a)
          })
        })
      })
    }, 10);
  },

  getPositions(ids,type, callback) {
    let key = 'position_'
    let data = []
    ids = ids.filter((id) => {
      let a = context.$storage.get(key + id)
      if (a) data.push(a)
      return !a
    })

    if (ids.length) {
      context.$http.get(`/position-search/${type}/${ids}`).then((result) => {
        result.body.data.forEach(a => {
          data.push(a)
          context.$storage.set(key + a.id, a)
        })
        callback(data)
      })
    }
    else {
      callback(data)
    }
  }
}