export default {
  _xxxxl: 1900,
  _xxxl: 1600,
  _xxl: 1400,
  _xxl_max: 1399,
  _xl: 1200,
  _xl_max: 1199,
  _lg: 992, 
  _lg_max: 991,
  _md: 768,
  _md_max: 767,
  _sm: 576,
  _sm_max: 575,
  _xs: 450,
  _xs_max: 449,
  _xxs: 350,
  _xxs_max: 349,
}