import "../../css/scss/components/_apartment_card.scss";

import Vue from "vue";
import store from "../commons/store";
import moment from "moment"
import { boolean } from "is_js";

Vue.component("apartment-card", {
  props: {
    id: { type: Number },
    residence: { type: Boolean, default: true },
    version: { default: 1 },
    slot: { type: Object },
    slots: { type: Array },
    campus: { type: Object },
    type: { type: String },
    showdistance:{default:true,type:Boolean}
  },

  computed: {


    colorClass() {

      return !this.residence ? 'color-primary-2' : ''
    },




  },

  watch: {
    id(value) {
      this.data = store.getApartment(this);
    }
  },

  data() {
    let data = store.getApartment(this);
    return {
      data: data || {}
    };
  },
  methods: {
    setData(a) {
      this.data = a;
    },
    getSlot() {
      const { slot, data, slots } = this;
      if (!data.slots_taken) return ""
      if (slot !== undefined) return `Available from ${slot.label}`;
      const ms = (d) => new Date(d).getTime()
      const slot_free = slots.find(s => {
        return !data.slots_taken.find(slot_taken => {
          if (!slot_taken) return false
          if (s.id === slot_taken.id) return true
          return !(ms(s.end_date) <= ms(slot_taken.start_date) || ms(s.start_date) >= ms(slot_taken.end_date))
        })
      })
      return slot_free ? `Available from ${slot_free.label}` : "Not available";
    }
  },

  template: "#apartment-card"
});
