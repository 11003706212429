// Css
import '../../css/scss/pages/home.scss'

// Js
import '../components/apartment_card'
import '../components/dropdown_select'
import '../components/stars'
import '../components/modal.js'
import VueResource from 'vue-resource'
import VueCarousel from 'vue-carousel'
import Vue from 'vue'
import utils from '../commons/utils'
import store from '../commons/store'

Vue.use(VueResource)
Vue.use(VueCarousel)

Vue.component('home', {
  created () {
    localStorage.clear()
    store.init({$http: this.$http, context: this})
  },
  data() {
    let data = this.$parent.getData()
    data.campus_selected = false
    data.dates_selected = false
  

    data.storiesOptions = {
      itemsPerPage: 3
    }
    data.stories = [
      {
        image: utils.domainStaticHost() + '/images/home/stories_img_1.png',
        title: 'Meet our students,<br> the ‘Beyonders’',
        url: 'https://medium.com/beyond-campus/meet-our-students-the-beyonders-72981206188f'
      },
      {
        image: utils.domainStaticHost() + '/images/home/stories_img_2.png',
        title: 'The good life feeling on<br> living in Madrid,<br> told by our students',
        url: 'https://medium.com/beyond-campus/the-best-year-of-my-life-literally-2d25d4f2c8bd'
      },
      {
        image: utils.domainStaticHost() + '/images/home/stories_img_3.png',
        title: 'The importance of<br> moving out of the box',
        url: 'https://medium.com/beyond-campus/the-importance-of-moving-out-of-the-box-af61a101c2af'
      }
    ]

    data.testimonials = [
      {
        avatar: utils.domainStaticHost() + '/images/home/img_avatar_01.png',
        score: 5,
        name: 'Julie Green',
        place: 'Madrid IE Campus',
        title: 'Best booking experience I ever had',
        description: 'Everything was great. I was skepticall at first but once I started using the platform and talking with them, I felt a lot of trustability. It was easier to find an apartment than I expected and they helped me with anything I needed.',
      },
      {
        avatar: utils.domainStaticHost() + '/images/home/img_avatar_02.png',
        score: 5,
        name: 'Juliana Bustamante',
        place: 'Madrid IE Campus',
        title: 'Best booking experience I ever had',
        description: 'I found a very nice place to live thanks to Beyond Campus, my experience in Madrid has been great and I believe a big part of it is because I managed to live in a great location.',
      },
    ]

    data.slot_filter=data.slot_filter.map((slot) => {
      slot.labelOption = slot.label + ' → ' + slot.end
      return slot
    })

    data.slots = data.slots.map((slot) => {
      slot.labelOption = slot.label + ' → ' + slot.end
      return slot
    })
    console.log(data.slots)
    return data
  },
  beforeMount() {
    this.handleResize()
  },
  mounted() {
    
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },



  methods: {
    changeCity(id){
      if(this.type_res!='residences')
      this.slot_filter=this.slots.filter(x=>x.city.find(y=>y.id==id.city_id))
    },
    exploreType(type) {
      location.href = `/${type}`
    },
    explore() {
      let url = `/search/${this.type_res}?`
      if(this.campus_selected){
        url += 'city=' + this.campus_selected.id
        if(this.dates_selected) url += '&'
      }
      if(this.dates_selected) url += 'slot=' + this.dates_selected.id

      location.href = url
    },
    handleResize() {
      // For stories carousel
      let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth < 575) {
        this.storiesOptions.itemsPerPage = 1
      } else if (viewportWidth < 767) {
        this.storiesOptions.itemsPerPage = 2
      } else {
        this.storiesOptions.itemsPerPage = 3
      }
    },
    showBannerFiltersModal (filter) {
      this.$refs.bannerFilters.show()
      let fcWidget = document.getElementById('fc_frame')
      fcWidget.style = 'bottom: 18rem !important;'
    },
    closeBannerFiltersModal (modal) {
      this.$refs[modal].hide()
      let fcWidget = document.getElementById('fc_frame')
      fcWidget.style = 'bottom: 0 !important;'
    },
  }
})
