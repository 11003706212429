import $screen from './screen'

let html = document.querySelector('html')
let body = document.querySelector('body')

const utils = {
  alphabet: 'abcdefghijklmnopqrstuvwxyz',
  emptyImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiYAAAAAkAAxkR2eQAAAAASUVORK5CYII=',
  waitingCallbacks: [],
  executedCallbacks: 0,
  responsiveWidth: 991,

  getLabelTime(value) {
    let textValue = '';
    let h = parseInt(value / 60);
    let m = value % 60;
    if (h) {
      textValue += h;
      if(h === 1 && m){
        textValue += ' h';
      }
      else{
        textValue += ' hours';
      }
    }
    if(m && h <= 1){
      textValue += ' ' + m + ' min';
    }
    return textValue;
  },

  random() {
    return `beyond_${Math.random().toString(36).substring(7)}`;
  },

  jsonp(url, callback, context) {
    let random = utils.random();
    window[random] = callback;
    utils.loadJs(url += random);
  },

  loadJs(url, callback, context) {
    let body = document.querySelector('body');
    let newScript = document.createElement("script");
    newScript.src = url;
    newScript.onload = callback;
    body.appendChild(newScript);
  },

  scrollTop() {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  },

  scrollToPos(position) {
    if(window.scrollTo){
      window.scrollTo(0, position);
    }
    else{
      html.scrollTop = (position || 0) + 'px'
    }
  },

  scrollToElement(pageElement, offsetX, offsetY) {
    let positionX = 0, positionY = 0

    while (pageElement != null) {
        positionX += pageElement.offsetLeft
        positionY += pageElement.offsetTop
        pageElement = pageElement.offsetParent
        window.scrollTo(positionX - (offsetX? offsetX: 0), positionY - (offsetY? offsetY: 0))
    }
  },

  getYoutubeEmbed(url) {
    let part = url.split('/').pop();
    try{
      part = part.split('v=')[1].split('&')[0]
    }catch(e){}
    let width = parseInt(window.innerWidth * 0.8);
    let height = parseInt(window.innerHeight * 0.8);
    if(height > width){
      height = width;
    }
    return {
      url: `https://www.youtube.com/embed/${part}`,
      width: width,
      height: height
    }
  },

  cloneObject(object) {
    return JSON.parse(JSON.stringify(object))
  },

  fontSize(desktop, mobile) {
    let w = window.innerWidth
    let h = window.innerHeight
    let px = 10
    if(w <= $screen._xl_max) px = 8.5
    if(w <= $screen._lg_max) px = 5
    if(w <= 850) px = 6
    if(w <= 850 && h >= 900) px = 10
    if(w <= 740 && h >= 900) px = 9
    if(w <= 600 && h >= 900) px = 7
    if(w <= 500 && h >= 800) px = 6.5
    if(w <= $screen._xs_max) px = 5.5
    if(w <= $screen._xxs_max) px = 5
    if(desktop && this.isDesktop()) px *= desktop
    if(mobile && this.isMobile()) px *= mobile
    px = parseInt(px*10)/10
    return px
  },

  isMobile() {
    return window.innerWidth <= $screen._lg_max
  },

  isDesktop() {
    return !this.isMobile()
  },

  domainStaticHost() {
   
    if(window.location.hostname === 'localhost' ){
      return 'http://localhost:9000'
    }
    if (window.staticsDomain) {
      return window.staticsDomain + '/static/dist'
    }
    return '/static/dist'
  },

  tokenize(str){
    let list = str.split(',')
    if(list.length === 1) list = str.split(' ')
    return list.map(e => e.trim())
  },

  sendMailchimpForm(data, callback) {
    let mailchimpScript = document.createElement('script')
    mailchimpScript.setAttribute('id', 'mailchimpForm');
    let body = document.querySelector('body')
    body.appendChild(mailchimpScript)
    mailchimpScript.src = `https://beyondcampus.us19.list-manage.com/subscribe/post-json?u=${data.u}&id=${data.id}&EMAIL=${data.email}&FNAME=${data.name}${(data.filters)? '&FILTERS='+data.filters: '' }&b_78c32918a1365aea2cd22fe21_988ba394b0=&suscribe=Suscribe&c=${data.c}`
    window[data.c] = callback ? callback : () => {}
  },
  urlParam(param) {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri)
    return params.get(param)
  },
  delegate(selector) {
    return (cb) => (e) => e.target.matches(selector) && cb(e)
  },
  capitalize(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export default utils;